<template>
  <v-container fill-height fluid>
    <v-card
      elevation="4"
      :width="cardWidth"
      class="mx-auto"
    >
      <v-card-title>{{ $t('cdp_settings') }}</v-card-title>

      <v-card-text>
        <v-list
          nav
          dense
        >
          <v-list-item-group color="black">
            <v-list-item
              v-for="item in availableMenuItems"
              :key="item.title"
              :to="getDestinationObj(item)"
              class="settings-list-item"
            >
              <v-list-item-icon>
                <v-icon color="defaultIconColor" v-text="item.icon"></v-icon>
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title v-text="$t(item.title)"></v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </v-card-text>
    </v-card>

  </v-container>
</template>

<style scoped>
  .settings-list-item {
    background-color: var(--v-primary-base);
    margin-bottom: 15px !important;
  }
</style>

<script>

import { mapState } from 'vuex'

export default {
  name: "CdpSettings",

  computed: {
    ...mapState({
      userRoles: state => state.core.auth.userRoles,
      menuItems: state => state.app.cdpSettingsItems,
    }),

    availableMenuItems()
    {
      return this.menuItems.filter(
        i => i.access_role.some(
          r => this.userRoles.includes(r)
        )
      )
    },
    
    cardWidth () {
      switch (this.$vuetify.breakpoint.name) {
        case 'md': return "50%"
        case 'lg': return "35%"
        case 'xl': return "35%"
        default: return "100%"
      }
    }
  },

  methods:{
    getDestinationObj(item) {
      return {
        name: item.routeName,
        params: { appId: this.$route.params.appId }
      }
    }
  },
}

</script>
